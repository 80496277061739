<template>
  <div class="page store profit settlement">
    <div class="ibox ">

      <div class="ibox-content">

        <div class="table-responsive">
          <table ref="list" class="table table-stripped toggle-arrow-tiny" data-paging="false">
            <thead>

              <tr class="fields text-left">
                <th :colspan="columns">

                  <div class="form-group row"><label class="col-sm-2 col-form-label">환율 (원)</label>
                    <div class="col-sm-10">
                      <input class="form-control" type="text" name="exchange_rate" v-model="exchange_rate" />
                    </div>
                  </div>

                  <div class="form-group row"><label class="col-sm-2 col-form-label">호스트 수익률 (%)</label>
                    <div class="col-sm-10">
                      <input class="form-control" type="text" name="profit_rate" v-model="profit_rate" />
                    </div>
                  </div>

                  <!-- <div class="form-group row"><label class="col-sm-2 col-form-label">주문당 제반비용 (원)</label>
                    <div class="col-sm-10">
                      <input class="form-control" type="text" name="expenses_per_order" v-model="expenses_per_order" />
                    </div>
                  </div>

                  <div class="form-group row"><label class="col-sm-2 col-form-label">주문당 제반비용 내용</label>
                    <div class="col-sm-10">
                      <textarea class="form-control" :rows="3" name="expenses_per_order_comment" v-model="expenses_per_order_comment" />
                    </div>
                  </div>

                  <div class="form-group row"><label class="col-sm-2 col-form-label">취소당 제반비용 (원)</label>
                    <div class="col-sm-10">
                      <input class="form-control" type="text" name="expenses_per_cancel" v-model="expenses_per_cancel" />
                    </div>
                  </div>

                  <div class="form-group row"><label class="col-sm-2 col-form-label">취소당 제반비용 내용</label>
                    <div class="col-sm-10">
                      <textarea class="form-control" :rows="3" name="expenses_per_cancel_comment" v-model="expenses_per_cancel_comment" />
                    </div>
                  </div> -->

                  <div class="form-group row"><label class="col-sm-2 col-form-label">기타 제반비용 (원)</label>
                    <div class="col-sm-10">
                      <input class="form-control" type="text" name="expenses_etc" v-model="expenses_etc" />
                    </div>
                  </div>

                  <div class="form-group row"><label class="col-sm-2 col-form-label">기타 제반비용 내용</label>
                    <div class="col-sm-10">
                      <textarea class="form-control" :rows="3" name="expenses_etc_comment" v-model="expenses_etc_comment" />
                    </div>
                  </div>

                  <div class="form-group row right">
                    <div class="date-range-input">
                      <div class="input-group">
                        <div class="input">
                          <datetime v-model="date_start" type="date" zone="Asia/Seoul" name="date_start" :week-start="0" placeholder="정산 시작일" />
                          <div class="divine"></div>
                          <datetime v-model="date_end" type="date" zone="Asia/Seoul" name="date_end" :week-start="0" placeholder="정산 종료일" />
                        </div>
                        <span class="input-group-append">
                          <button type="button" class="btn btn-submit" @click="onSubmit">Search</button>
                          <button type="button" class="btn btn-submit">
                            <export-excel :name="exportName" :export-fields="exportFields" :data="exportItems" :before-generate="loadExport">Export</export-excel>
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>

            <thead v-if="items.length > 0">
              <tr>
                <th :colspan="columns">
                  <div class="info">Showing <strong>{{total}}</strong> entries</div>
                  <div class="padding"></div>
                </th>
              </tr>
              <tr class="fields">
                <th scope="col" class="checkbox">
                  <input-checkbox v-model="checkedAll" name="checked[]" expected="all" indeterminated="half" :ripple="true" @click="onClickCheckedAll" ></input-checkbox>
                </th>
                <th scope="col">상점id</th>
                <th scope="col">상점명</th>
                <th scope="col">호스트연락처</th>
                <th scope="col">호스트이메일</th>
                <th scope="col" class="right">매출기준액</th>
                <th scope="col" class="right">실결제금액</th>
                <th scope="col" class="right">정산비율</th>
                <th scope="col" class="right">호스트정산금액</th>
                <th scope="col" class="right">세금계산서방식</th>
                <th scope="col" class="right">원천징수방식</th>
                <th scope="col">상세보기</th>
              </tr>
            </thead>
            <tbody v-if="items.length > 0" :data-timestamp="timestamp">
              <template v-for="row in items">
                <tr :key="row.store_id" :class="{'has-settlement': row.has_settlements}">
                  <td class="checkbox">
                    <input-checkbox v-model="checked[row.store_id]" name="checked[]" 
                      :expected="true" :disabled="row.has_settlements" :ripple="true" @click="onClickCheck"></input-checkbox>
                  </td>
                  <td>{{row.store_id}}</td>
                  <td>{{row.store_name_localed}}</td>
                  <td>{{row.store_notify_phone}}</td>
                  <td>{{row.store_notify_email}}</td>
                  <td class="right">{{$utils.numberFormat(row.settlement_total)}}원</td>
                  <td class="right">{{$utils.numberFormat(row.paid_total)}}원</td>
                  <td class="right">{{$utils.numberFormat(row.profit_rate)}}%</td>
                  <td>{{row.total_profit}}원</td>
                  <td class="right">{{$utils.numberFormat(row.tax_bill)}}원</td>
                  <td class="right">{{$utils.numberFormat(row.withholding_tax)}}원</td>
                  <td>
                    <button type="button" class="btn" :class="{'btn-primary': row.opened, 'btn-default': !row.opened}" @click="onClickShow(row)">{{row.opened ? '상세보기' : '상세보기'}}</button>
                  </td>
                </tr>

                <tr :key="row.store_id + '.data'"  v-if="row.opened">
                  <td :colspan="columns" class="row-detail">
                    <table class="table" data-paging="false">
                      <tbody>
                        <tr>
                          <td class="text-left" >
                            <h3>달러 주문건 </h3>

                            <h4>주문 합계</h4>

                            <ul class="total">
                              <li>
                                <dl>
                                  <dt>주문금액</dt> <dd>$ {{$utils.numberFormat(row.extras.usd.ordered)}}</dd>
                                </dl>
                              </li>
                              <li>
                                <dl>
                                  <dt>결제금액</dt> <dd>$ {{$utils.numberFormat(row.extras.usd.paid)}}</dd>
                                </dl>
                              </li>
                              <li>
                                <dl>
                                  <dt>취소금액</dt> <dd>$ {{$utils.numberFormat(row.extras.usd.cancelled)}}</dd>
                                </dl>
                              </li>
                              <!-- <li>
                                <dl>
                                  <dt>호스트 매출</dt> <dd>$ {{$utils.numberFormat(row.extras.usd.profit)}}</dd>
                                </dl>
                              </li> -->
                            </ul>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">
                            <h4>건수 / 수량</h4>

                            <ul class="total">
                              <li>
                                <dl>
                                  <dt>주문건수</dt> <dd>{{$utils.numberFormat(row.extras.usd.count)}} 건</dd>
                                </dl>
                                <dl>
                                  <dt>작은물품</dt> <dd>{{$utils.numberFormat(row.extras.usd.luggage_quantity)}} 개</dd>
                                </dl>
                                <dl>
                                  <dt>큰물품</dt> <dd>{{$utils.numberFormat(row.extras.usd.luggage_over_quantity)}} 개</dd>
                                </dl>
                              </li>
                              <li>
                                <dl>
                                  <dt>결제건수</dt> <dd>{{$utils.numberFormat(row.extras.usd.paid_count)}} 건</dd>
                                </dl>                                
                                <dl>
                                  <dt>작은물품</dt> <dd>{{$utils.numberFormat(row.extras.usd.luggage_quantity_paid)}} 개</dd>
                                </dl>
                                <dl>
                                  <dt>큰물품</dt> <dd>{{$utils.numberFormat(row.extras.usd.luggage_over_quantity_paid)}} 개</dd>
                                </dl>
                              </li>
                              <li>
                                <dl>
                                  <dt>취소건수</dt> <dd>{{$utils.numberFormat(row.extras.usd.cancel_count)}} 건</dd>
                                </dl>                                
                                <dl>
                                  <dt>작은물품</dt> <dd>{{$utils.numberFormat(row.extras.usd.luggage_quantity_cancel)}} 개</dd>
                                </dl>
                                <dl>
                                  <dt>큰물품</dt> <dd>{{$utils.numberFormat(row.extras.usd.luggage_over_quantity_cancel)}} 개</dd>
                                </dl>
                              </li>
                              <!-- <li>
                                <dl>
                                  <dt>주문당 제반비용</dt> <dd>{{row.extras.usd.order_count}} 건 x {{row.expenses_per_order}} 원 = {{$utils.numberFormat(row.extras.usd.expenses_per_order)}} 원</dd>
                                </dl>
                                <dl>
                                  <dt>취소당 제반비용</dt> <dd>{{row.extras.usd.cancel_count}} 건 x {{row.expenses_per_cancel}} 원 = {{$utils.numberFormat(row.extras.usd.expenses_per_cancel)}} 원</dd>
                                </dl>                                
                                <dl>
                                  <dt>PG 수수료</dt> <dd>$ {{$utils.numberFormat(row.extras.usd.payment_fee)}}</dd>
                                </dl>
                              </li> -->
                            </ul>
                          </td>
                        </tr>
                      </tbody>

                      <tbody>
                        <tr>
                          <td class="text-left" >
                            <h3>원화 주문건 </h3>

                            <h4>주문 합계</h4>

                            <ul class="total">
                              <li>
                                <dl>
                                  <dt>주문금액</dt> <dd>{{$utils.numberFormat(row.extras.krw.ordered)}} 원</dd>
                                </dl>
                              </li>
                              <li>
                                <dl>
                                  <dt>결제금액</dt> <dd>{{$utils.numberFormat(row.extras.krw.paid)}} 원</dd>
                                </dl>
                              </li>
                              <li>
                                <dl>
                                  <dt>취소금액</dt> <dd>{{$utils.numberFormat(row.extras.krw.cancelled)}} 원</dd>
                                </dl>
                              </li>                              
                              <!-- <li>
                                <dl>
                                  <dt>호스트 매출</dt> <dd>{{$utils.numberFormat(row.extras.krw.profit)}} 원</dd>
                                </dl>
                              </li> -->
                            </ul>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">
                            <h4>건수 / 수량</h4>

                            <ul class="total">
                              <li>
                                <dl>
                                  <dt>주문건수</dt> <dd>{{$utils.numberFormat(row.extras.krw.count)}} 건</dd>
                                </dl>
                                <dl>
                                  <dt>작은물품</dt> <dd>{{$utils.numberFormat(row.extras.krw.luggage_quantity)}} 개</dd>
                                </dl>
                                <dl>
                                  <dt>큰물품</dt> <dd>{{$utils.numberFormat(row.extras.krw.luggage_over_quantity)}} 개</dd>
                                </dl>
                              </li>
                              <li>
                                <dl>
                                  <dt>결제건수</dt> <dd>{{$utils.numberFormat(row.extras.krw.paid_count)}} 건</dd>
                                </dl>
                                <dl>
                                  <dt>작은물품</dt> <dd>{{$utils.numberFormat(row.extras.krw.luggage_quantity_paid)}} 개</dd>
                                </dl>
                                <dl>
                                  <dt>큰물품</dt> <dd>{{$utils.numberFormat(row.extras.krw.luggage_over_quantity_paid)}} 개</dd>
                                </dl>
                              </li>
                              <li>
                                <dl>
                                  <dt>취소건수</dt> <dd>{{$utils.numberFormat(row.extras.krw.cancel_count)}} 건</dd>
                                </dl>
                                <dl>
                                  <dt>작은물품</dt> <dd>{{$utils.numberFormat(row.extras.krw.luggage_quantity_cancel)}} 개</dd>
                                </dl>
                                <dl>
                                  <dt>큰물품</dt> <dd>{{$utils.numberFormat(row.extras.krw.luggage_over_quantity_cancel)}} 개</dd>
                                </dl>
                              </li>
                              <!-- <li>
                                <dl>
                                  <dt>주문당 제반비용</dt> <dd>{{row.extras.krw.order_count}} 건 x {{row.expenses_per_order}} 원 = {{$utils.numberFormat(row.extras.krw.expenses_per_order)}} 원</dd>
                                </dl>
                                <dl>
                                  <dt>취소당 제반비용</dt> <dd>{{row.extras.krw.cancel_count}} 건 x {{row.expenses_per_cancel}} 원 = {{$utils.numberFormat(row.extras.krw.expenses_per_cancel)}} 원</dd>
                                </dl>                                
                                <dl>
                                  <dt>PG 수수료</dt> <dd>{{$utils.numberFormat(row.extras.krw.payment_fee)}} 원</dd>
                                </dl>
                              </li> -->
                            </ul>
                          </td>
                        </tr>
                      </tbody>

                      <tfoot>
                        <tr>
                          <td class="text-left" >
                            <h3>총 합계 </h3>

                            <h4>주문 합계</h4>

                            <ul class="total">
                              <li>
                                <dl>
                                  <dt>주문금액</dt> <dd>{{$utils.numberFormat(row.total_ordered)}} 원</dd>
                                </dl>
                              </li>
                              <li>
                                <dl>
                                  <dt>결제금액</dt> <dd>{{$utils.numberFormat(row.total_paid)}} 원</dd>
                                </dl>
                              </li>
                              <li>
                                <dl>
                                  <dt>취소금액</dt> <dd>{{$utils.numberFormat(row.total_cancelled)}} 원</dd>
                                </dl>
                              </li>
                              <!-- <li>
                                <dl>
                                  <dt>호스트 매출</dt> <dd>{{$utils.numberFormat(row.total_profit)}} 원</dd>
                                </dl>
                              </li> -->
                            </ul>
                          </td>
                        </tr>
                        <tr>
                          <td :colspan="columns" class="text-left">

                            <h4>제반비용 </h4>

                            <ul class="total">
                              <li>
                                <dl>
                                  <dt>PG 결제 수수료</dt> <dd>{{$utils.numberFormat(row.total_payment_fee)}} 원</dd>
                                </dl>
                                <dl>
                                  <dt>기타 제반비용</dt> <dd>{{$utils.numberFormat(row.expenses_etc)}} 원</dd>
                                </dl>
                              </li>
                              <li>
                                <dl>
                                  <dt>호스트 부담 제반비용</dt> <dd>{{$utils.numberFormat(row.total_expenses_basic)}} 원</dd>
                                </dl>
                                <!-- <dl>
                                  <dt>주문당 제반비용</dt> <dd>{{$utils.numberFormat(row.total_expenses_per_order)}} 원</dd>
                                </dl>
                                <dl>
                                  <dt>취소당 제반비용</dt> <dd>{{$utils.numberFormat(row.total_expenses_per_cancel)}} 원</dd>
                                </dl> -->
                              </li>
                              <li>
                                <dl>
                                  <dt>총 제반비용</dt> <dd>{{$utils.numberFormat(row.total_expenses)}} 원</dd>
                                </dl>
                              </li>
                            </ul>

                          </td>
                        </tr>
                        <tr>
                          <td :colspan="columns" class="text-left">

                            <h4>호스트 수익</h4>

                            <ul class="total">
                              <li>
                                <dl>
                                  <dt>총 매출</dt> <dd>{{$utils.numberFormat(row.total_paid)}} 원</dd>
                                </dl>
                                <dl>
                                  <dt>공동 부담 제반비용</dt> <dd>{{$utils.numberFormat(row.total_payment_fee + row.expenses_etc)}} 원</dd>
                                </dl>
                                <dl>
                                  <dt>총 수익</dt> <dd>{{$utils.numberFormat(row.total_paid - row.total_payment_fee - row.expenses_etc)}} 원</dd>
                                </dl>
                              </li>
                              <li>
                                <dl>
                                  <dt>수익률</dt> <dd>{{row.profit_rate}} %</dd>
                                </dl>
                              </li>
                              <li>
                                <dl>
                                  <dt>호스트 매출</dt> <dd>{{$utils.numberFormat(row.total_profit)}} 원</dd>
                                </dl>
                                <dl>
                                  <dt>호스트 부담 제반비용</dt> <dd>{{$utils.numberFormat(row.total_expenses_basic)}} 원</dd>
                                </dl>
                                <dl>
                                  <dt>호스트 수익</dt> <dd>{{$utils.numberFormat(row.total_income)}} 원</dd>
                                </dl>                                
                                <!-- <dl>
                                  <dt>세금</dt> <dd>{{$utils.numberFormat(row.total_income_tax)}} 원</dd>
                                </dl>
                                <dl>
                                  <dt>공급가액</dt> <dd>{{$utils.numberFormat(row.total_income_amount)}} 원</dd>
                                </dl> -->
                              </li>
                            </ul>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </td>
                </tr>
              </template>

              <tr v-if="has_settlements || items.length === 0 || checkable_stores === 0">
                <td :colspan="columns" class="errors">
                  <p v-if="checkable_stores > 0 && has_settlements">일부 정산 내역이 존재합니다. 해당 상점은 기존 정산을 처리 후에 생성 가능합니다.</p>
                  <p v-if="items.length === 0">정산할 주문 내역이 없습니다.</p>
                  <p v-if="items.length > 0 && checkable_stores === 0">정산할 수 있는 주문 내역이 없습니다.</p>
                </td>
              </tr>
            </tbody>

            <tfoot v-if="items.length > 0">
              <tr>
                <td :colspan="columns" class="actions">
                  <div class="left">
                    <button class="btn btn-white btn-sm" @click="onClickBack">Back</button>
                  </div>
                  <div class="right">
                    <button type="button" class="btn btn-primary btn-submit" :disabled="!canSettle" @click="onClickSettlement">정산데이타 일괄 생성</button>
                  </div>
                </td>
              </tr>
            </tfoot>

          </table>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/elements/Pagination"
import SearchInput from "@/components/utils/SearchInput"
import StoreSelect from "@/components/utils/StoreSelect"

export default {
  mounted() {
    this.$data.mode = this.$route.meta.mode
    this.$data.model = this.$route.meta.model
  },
  data() {
    let defaultParams = this.$store.getters['store/batchProfitDefaultParams'] || {}
    let exchangeRate = this.$store.getters['store/exchangeRate'] || 1100
    
    return {
      showExport: false,
      columns: 9,
      mode: 'unset',
      model: {},
      page: 1,
      size: 10,
      total: 0,
      data: {},
      items: [],
      checkable_stores: 0,
      checkedAll: 'none',
      checked: {},
      has_settlements: false,
      profit_rate: 50.0,
      expenses_etc: 0,
      expenses_etc_comment: '',
      expenses_per_order: 0,
      expenses_per_order_comment: '',
      expenses_per_cancel: 0,
      expenses_per_cancel_comment: '',
      exchange_rate: exchangeRate,
      date_start: defaultParams.date_start || '',
      date_end: defaultParams.date_end || '',
      timestamp: +new Date(),

      exportItems: [],
    }
  },
  watch: {
    exchange_rate(value, oldValue) {
      if (value) {
        this.$store.dispatch('store/setExchangeRate', {
          exchange_rate: value
        })
      }
    }
  },
  computed: {
    canSettle() {
      return this.items.length > 0 && this.checkable_stores > 0
    },
    storeIds() {
      let store_ids = []
      for (var store_id in this.checked) {
        if (this.checked[store_id]) {
          store_ids.push(parseInt(store_id))
        }
      }
      return store_ids
    },
    exportName() {
      return `${this.model.name}_settlement.${this.$moment().format('YYYYMMDD')}.xls`
    },
    exportFields() {
      if (this.model.types.profitExport) {
        return this.model.types.profitExport.fields
      }
      return {}
    }
  },
  methods: {

    onClear() {

    },
    onPage() {

    },
    toggleUtil(action) {
      if (action === 'excel') {
        this.$data.showExport = !this.showExport
      }

      if (action === 'json') {
        this.$data.showExport = !this.showExport
      }
    },
    onSubmit() {
      let params = { 
        date_start: this.$moment.tz(this.date_start, "Asia/Seoul").format("YYYY-MM-DD"), 
        date_end: this.$moment.tz(this.date_end, "Asia/Seoul").format("YYYY-MM-DD"), 
        exchange_rate: this.exchange_rate,
        profit_rate: this.profit_rate,
        expenses_per_order: this.expenses_per_order,
        expenses_per_cancel: this.expenses_per_cancel,
        expenses_etc: this.expenses_etc
      }

      this.$store.dispatch(this.model.types.batchProfit.action, params)
        .then((data) => {
          let { items } = data
          this.$data.items = items
          this.$data.total = items.length

          let has_settlements = false
          let checked = {}
          let checkable_stores = 0
          let hasChecked = false

          this._.forEach(items, item => {
            if (item.has_settlements) {
              has_settlements = true
            }
            else {
              hasChecked = true
              checkable_stores = checkable_stores + 1
              checked[item.store_id] = true
            }
          })

          this.$data.has_settlements = has_settlements
          this.$data.checked = checked
          this.$data.checkable_stores = checkable_stores
          this.$data.checkedAll = hasChecked ? 'all' : 'none'
        })
        .catch(e => {
          this.$toast.error(e.message)
        })
      },
      
      loadExport() {
      let params = {
        group: this.groupId,
        profit_rate: this.profit_rate,
        date_start: this.limitDateStart || this.date_start,
        date_end: this.limitDateEnd || this.date_end,
      }

      return new Promise((resolve, reject) => {
        this.$store.dispatch(this.model.types.profitExport.action, params)
          .then((data) => {
            let { items, fields } = data

            if (items.length === 0) {
              this.$toast.error("추출할 데이타가 없습니다.")
            }

            this.$data.exportItems = items
            resolve(data)
          })
          .catch(e => {
            this.$toast.error(e.message)
            reject(e)
          })
      })
    },
    onClickCheckedAll(e) {
      if (e.value === 'all') {
        let checked = {}
        this._.forEach(this.items, item => {
          checked[item.store_id] = true
        })
        this.$data.checked = checked
      }
      else if (e.value === undefined) {
        let checked = {}
        this._.forEach(this.items, item => {
          checked[item.store_id] = false
        })
        this.$data.checked = checked
      }
    },

    onClickCheck(e) {
      setTimeout(() => {
        let hasChecked = false, hasUnchecked = false

        this._.forEach(this.checked, flag => {
          if (flag) {
            hasChecked = true
          }
          else {
            hasUnchecked = true
          }
        })

        if (hasChecked === true && hasUnchecked === false) {
          this.$data.checkedAll = "all"
        }
        else if (hasChecked === true && hasUnchecked === true) {
          this.$data.checkedAll = "half"
        }
        else if (hasChecked === false && hasUnchecked === true) {
          this.$data.checkedAll = "none"
        }
      }, 0)
    },

    onClickBack() {
      this.$router.back()
    },

    onClickShow(row) {
      row.opened = row.opened === true ? false : true
      this.$data.timestamp = +new Date()
    },

    onClickSettlement() {
      let store_ids = this.storeIds

      if (store_ids.length === 0) {
        this.$toast.warning("1개 이상 선택해주세요")
        return
      }

      let params = { 
        store_ids: store_ids,
        date_start: this.$moment.tz(this.date_start, "Asia/Seoul").format("YYYY-MM-DD"), 
        date_end: this.$moment.tz(this.date_end, "Asia/Seoul").format("YYYY-MM-DD"), 
        exchange_rate: this.exchange_rate,
        profit_rate: this.profit_rate,
        expenses_per_order: this.expenses_per_order,
        expenses_per_order_comment: this.expenses_per_order_comment,
        expenses_per_cancel: this.expenses_per_cancel,
        expenses_per_cancel_comment: this.expenses_per_cancel_comment,
        expenses_etc: this.expenses_etc,
        expenses_etc_comment: this.expenses_etc_comment
      }

      this.$store.dispatch(this.model.types.batchSettlement.action, params)
        .then((data) => {

          this.$toast.success("생성하였습니다.")
          let { uid } = data

          this.$router.push({
            path: `/settlement/list?page=1&size=20&group=NEW&keyword=`
          })
        })
        .catch(e => {
          this.$toast.error(e.message)
        })
    },

  },
  components: {
    Pagination, SearchInput, StoreSelect
  }
}
</script>
